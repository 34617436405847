@tailwind base;
@tailwind components;
@tailwind utilities;

.MuiSvgIcon-root {
    color: #FFFFFF;
}

.MuiTablePagination-displayedRows {
    color: #FFFFFF;
    margin-bottom: 0;
}